import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Form, Icon, Button, Row, message, Input, Col } from 'antd'
import { FormItem, TextButton, AuthHeader } from '../../components'

import { firebaseAuth, userData } from '../../firebase'
import { firebaseErrorHandler } from '../../utils/helpers'

import { withFormik } from 'formik'
import * as yup from 'yup'
import { yupEmail, yupPassword } from '../../utils/yupMessages'
import { pagarmeConnect } from '../../services'


const Login = withFormik({
  validateOnBlur: false,

  mapPropsToValues: () => ({ email: '', password: '' }),
  validationSchema: yup.object().shape({
    email: yupEmail(),
    password: yupPassword()
  }),

  handleSubmit: async (values, { props, setSubmitting }) => {
    const { email, password } = values


    firebaseAuth.signInWithEmailAndPassword(email, password)
      .then(async () => {
        const user = await userData()
        if (!user) return

        if (!user.subscriptionId) {
          props.history.push('/')
          return
        }
        pagarmeConnect()
          .then(client => client.subscriptions.find({ id: user.subscriptionId }))
          .then(subscription => {
            if (subscription.status === 'paid') {
              props.history.push('/')
            } else {
              message.error('Conta bloqueada. Por favor, contate nosso suporte', 10)
            }
          })
      })
      .catch(err => {
        firebaseErrorHandler(err)
        setSubmitting(false)
      })
  }
})


const LoginForm = props => {
  const { handleSubmit, isSubmitting, errors } = props

  useEffect(() => {
    if (firebaseAuth.currentUser) {
      firebaseAuth.signOut()
    }
  }, [])

  const forgotPassword = async () => {
    const { email } = props.values
    if (!email || errors.email) {
      message.error('Informe um email válido!')
      return
    }

    try {
      await firebaseAuth.sendPasswordResetEmail(email)
      message.success('Email enviado! Verifique sua caixa de entrada :)')
    } catch (err) {
      message.error('Email não encontrado, verifique e tente novamente :/')
    }
  }

  return (
    <AuthHeader>
      <Form onSubmit={handleSubmit} hideRequiredMark>
        <Row type='flex' justify='center'>
          <Col md={15} lg={15}>
            <FormItem
              label='Email'
              name='email'
              prefix={<Icon type='user' style={css.icons} />}
              required
            />
            <FormItem
              label='Senha'
              name='password'
              prefix={<Icon type='lock' style={css.icons} />}
              type='password'
              component={Input.Password}
              visibilityToggle
              required
            />
            <Form.Item>
              <Row type='flex' justify='center'>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSubmitting}
                  icon='check'
                  block
                >
                  Mover-se
                </Button>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row type='flex' justify='space-between'>
                <TextButton onClick={forgotPassword}>Esqueci minha senha</TextButton>
                <Link to='/registrar'>Registrar</Link>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </AuthHeader>
  )
}


const css = {
  icons: {
    color: 'rgba(0,0,0,.25)'
  }
}

export default Login(LoginForm)
