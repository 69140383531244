import styled from '@emotion/styled'
import { mainColor } from '../utils/constants'

const TextButton = styled.span`
	color: ${mainColor};
	cursor: pointer;
	:hover {
		color: #d16973;
	}
`

export default TextButton
