import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Layout, Menu, Icon } from 'antd'
import styled from '@emotion/styled'
import { firebaseAuth, userData } from '../../firebase'
import { setUserData } from '../../store/user'
import { unlockSidebar, setPlans } from '../../store/global'

import { requestPlans } from '../../services'

const { Content, Sider } = Layout
const { SubMenu } = Menu

const ChidrenContent = styled(Content)`
	margin: 0px 16px;
`

const InnerLayout = styled(Layout)`
	min-height: 100vh;
`

const Link = ({ icon, to, text }) => (
  <NavLink to={to}>
    <Icon type={icon} />
    <span>{text}</span>
  </NavLink>
)

const DropdownLink = ({ icon, text }) => (
  <span>
    <Icon type={icon} />
    <span>{text}</span>
  </span>
)


const Main = props => {

  const isLocked = useSelector(state => state.global.isSidebarLocked)
  const dispatch = useDispatch()
  const planList = useSelector(({ global }) => global.plans)

  useEffect(() => {
    const getMySubscription = async () => {
      const user = await userData()

      if (user && user.myPlan) {
        const myPlan = planList.find(plan => plan.id === user.myPlan)
        dispatch(setUserData({ ...user, myPlan }))

        if (user.myPlan) {
          dispatch(unlockSidebar())
        }
      }
    }

    getMySubscription()
  }, [])

  useEffect(() => {

    const getPlans = async () => {
      const plans = await requestPlans()
      dispatch(setPlans(plans))
    }

    getPlans()
  }, [])

  const menuParams = { disabled: isLocked }

  return (
    <InnerLayout>
      <Sider collapsible theme='light'>
        <div className='logo' />
        <Menu defaultSelectedKeys={['home']} mode='inline'>
          <Menu.Item key='home' {...menuParams}>
            <NavLink to='/'>
              <Icon type='home' />
              <span>Início</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key='/metodos/cadastro' {...menuParams}>
            <NavLink to='/metodos/cadastro'>
              <Icon type='compass' />
              <span>Métodos</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key='biografia' {...menuParams}>
            <Link
              icon='idcard'
              to='/biografia'
              text='Biografia'
            />
          </Menu.Item>

          <Menu.Item key='meus-alunos' {...menuParams}>
            <Link
              icon='team'
              to='/meus-alunos'
              text='Meus Alunos'
            />
          </Menu.Item>
          <Menu.Item key='my-plan' {...menuParams}>
            <Link
              icon='shopping-cart'
              to='/meu-plano'
              text='Meu Plano'
            />
          </Menu.Item>
          <Menu.Item key='minhas-financas' {...menuParams}>
            <Link
              icon='dollar'
              to='/minhas-financas'
              text='Minhas finanças'
            />
          </Menu.Item>
          <Menu.Item key='informacoes_app' {...menuParams}>
            <Link
              icon='appstore'
              to='/informacoes_app'
              text='Informações do app'
            />
          </Menu.Item>

          <SubMenu
            key='settings'
            title={<DropdownLink icon='setting' text='Minha conta' />}
          >
            <Menu.Item
              key='leave'
              onClick={() => firebaseAuth.signOut()}
            >
              <Icon type='arrow-left' />
              Sair
						</Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <ChidrenContent>
          {props.children}
        </ChidrenContent>
      </Layout>
    </InnerLayout>
  )
}


export default Main
