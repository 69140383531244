import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Modal, Form, Col, Row, Input, Radio } from 'antd'
import { FormItem, InputNumber, SearchRemote } from '../../../../components'

import { withFormik } from 'formik'
import yup, { yupName, yupRequired } from '../../../../utils/yupMessages'

const propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  valuesEdition: PropTypes.object
}

const NewClass = withFormik({
  displayName: 'NewClass',
  mapPropsToValues: () => ({
    name: '',
    time: '',
    description: '',
    students: [],
    typeClass: 'generica'
  }),
  handleSubmit: async (values, { resetForm, props }) => {
    props.onCancel({ ...values, time: values.time * 60 })
    resetForm()
  },
  validationSchema: yup.object().shape({
    name: yupName().max(55, 'Informe até 55 caracteres'),
    time: yupRequired('o tempo').nullable(),
    description: yupRequired('a descrição')
  })
})


const InnerNewClass = props => {
  const {
    visible,
    onCancel,
    resetForm,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValid,
    setValues,
    valuesEdition,
    values
  } = props

  const { students, typeClass } = values

  const cancel = () => {
    onCancel(false)
    resetForm()
  }

  useEffect(() => {
    if (!valuesEdition.name) return
    const values = { ...valuesEdition, time: valuesEdition.time / 60 }
    setValues(values)
  }, [valuesEdition.name])

  const getStudents = arr => setFieldValue('students', arr)

  const changeRadio = ev => {
    const { value } = ev.target
    if (value === 'generica')
      setFieldValue('students', [])

    setFieldValue('typeClass', value)
  }

  const okButtonProps = {
    loading: isSubmitting,
    disabled: !isValid
  }

  return (
    <Modal
      title='Dados da aula'
      okText='Cadastrar'
      cancelText='Cancelar'
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={cancel}
      visible={visible}
      okButtonProps={okButtonProps}
      centered
    >
      <Form layout='vertical'>
        <Row>
          <Col span={24}>
            <FormItem
              name='name'
              label='Nome'
              required
            />
          </Col>
          <Col span={24}>
            <FormItem
              name='description'
              label='Descrição'
              component={Input.TextArea}
              rows={10}
              required
            />
          </Col>
          <Col span={24}>
            <FormItem
              label='Tempo médio (em minutos)'
              name='time'
              onChange={val => setFieldValue('time', val)}
              component={InputNumber}
              required
            />
          </Col>
          <Col span={24}>
            <FormItem name='video1' label='Recurso 1 (Vídeo/Imagem)' />
            <FormItem name='video2' label='Recurso 2 (Vídeo/Imagem)' />
            <FormItem name='video3' label='Recurso 3 (Vídeo/Imagem)' />
            <FormItem name='video4' label='Recurso 4 (Vídeo/Imagem)' />
            <FormItem name='video5' label='Recurso 5 (Vídeo/Imagem)' />
            <FormItem name='video6' label='Recurso 6 (Vídeo/Imagem)' />
            <FormItem name='video7' label='Recurso 7 (Vídeo/Imagem)' />
            <FormItem name='video8' label='Recurso 8 (Vídeo/Imagem)' />
            <FormItem name='video9' label='Recurso 9 (Vídeo/Imagem)' />
          </Col>
          <Col span={24}>
            <Form.Item label='Tipo da aula' colon={false}>
              <Radio.Group
                value={typeClass}
                onChange={changeRadio}
                buttonStyle='solid'
              >
                <Radio.Button value='generica'>Genérica</Radio.Button>
                <Radio.Button value='especifica'>Específica</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {typeClass === 'especifica' && (
              <SearchRemote
                setValues={getStudents}
                values={students}
              />
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

NewClass.propTypes = propTypes

export default NewClass(InnerNewClass)
