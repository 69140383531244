import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'
import debounce from 'lodash.debounce'
import { search } from '../services'
import PropTypes from 'prop-types'

const Option = Select.Option

const SearchRemote = props => {
  const [data, setData] = useState([])
  const [values, setValues] = useState([])
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    fillValues()
  }, [])

  const fillValues = () => {
    const values = props.values.map(item => ({ key: item.id, label: item.name }))
    setValues(values)
  }

  const handleChange = arrayValues => {
    setValues(arrayValues)
    const values = arrayValues.map(item => ({ id: item.key, name: item.label }))
    props.setValues(values)
  }

  const fetchUser = async text => {
    setData([])
    setFetching(true)
    const results = await search(text, 'searchableName')
    const data = results.map(user => ({
      text: user.name,
      value: user.id
    }))
    setData(data)
    setFetching(false)
  }

  return (
    <Select
      value={values}
      onChange={handleChange}
      placeholder='Informe os alunos'
      onSearch={debounce(fetchUser, 800)}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      mode='multiple'
      style={{ width: '100%' }}
      filterOption={false}
      labelInValue
    >
      {data.map(item => (
        <Option key={item.value}>
          {item.text}
        </Option>
      ))}
    </Select>
  )
}

SearchRemote.propTypes = {
  values: PropTypes.array.isRequired
}
SearchRemote.defaultProps = {
  values: []
}

export default SearchRemote
