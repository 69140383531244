import styled from '@emotion/styled'
import { mainColor } from '../utils/constants'

const genColor = props => props.colored ? mainColor : 'black'

export const MainHeader = styled.h1`
	font-weight: bold;
	font-size: 2em;
	color: ${genColor};
`

export const SecondHeader = styled.h2`
	font-weight: bold;
	color: ${props => props.colored ? mainColor : 'black'};
`


export default { MainHeader, SecondHeader }

