import { createSlice } from '@reduxjs/toolkit'

const globalSlice = createSlice({
  name: 'global',

  initialState: {
    isSidebarLocked: true,
    plans: [],
  },

  reducers: {
    unlockSidebar(state) {
      state.isSidebarLocked = false
    },

    lockSidebar(state) {
      state.isSidebarLocked = true
    },

    setPlans(state, action) {
      state.plans = action.payload
    }
  }
})

export default globalSlice.reducer

export const {
  unlockSidebar,
  lockSidebar,
  setPlans
} = globalSlice.actions
