import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Row, Col, Button, Card, message, Form, Popconfirm } from 'antd'
import { ButtonColor, Headers } from '../../components'

import { userData, trainerCollection, planSalesCollection, firestore } from '../../firebase'
import { formatToDecimal, defaultCatch, userId } from '../../utils/helpers'
import { pagarmeConnect } from '../../services'

import { useDispatch, useSelector } from 'react-redux'
import { unlockSidebar, lockSidebar } from '../../store/global'

const { MainHeader, SecondHeader } = Headers

const CardStyled = styled(Card)`
	text-align: center;
	box-shadow: 0px 3px 5px #d3d3d3;
  margin-bottom: 10px;
`

// const Title = styled.p`
// 	font-size: 18px;
// 	vertical-align: middle;
// 	text-align: center;
// 	margin: 0px;
// 	font-weight: bold;
// `

const Description = styled.p`
	font-size: 24px;
	margin: 2em 1em;
  font-weight: bold;
`

// const MaxStudents = styled.p`
// 	font-size: 22px;
// `

const Price = styled.span`
	font-size: 3em;
	font-weight: bold;
	color: black;
`

const MinorText = styled.span`
  vertical-align: ${props => props.align};
	font-weight: bold;
`

const MyPlan = () => {
  const [myPlan, setMyPlan] = useState({})
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState()

  const dispatch = useDispatch()
  const plansList = useSelector(({ global }) => global.plans)

  useEffect(() => {
    getMySubscription()
  }, [])

  const getMySubscription = async () => {
    const user = await userData()
    if (!user) return

    if (user.myPlan) {
      setMyPlan({ id: user.myPlan })
      dispatch(unlockSidebar())
    }

    if (user.subscriptionId) {
      setSubscription(user.subscriptionId)
    }
  }

  const submit = async () => {
    setLoading(true)
    const user = await userData()

    const finalAmount = myPlan.amount * 100

    const PagarMeCheckout = window.PagarMeCheckout
    const checkout = new PagarMeCheckout.Checkout({
      encryption_key: 'ek_test_39GdhEJfI7RAkS3dOACLYVKnxtuj8o',
      success: async data => {
        const subscription = {
          payment_method: 'credit_card',
          plan_id: myPlan.id,
          card_hash: data.card_hash,
          amount: finalAmount,
          customer: {
            name: user.name,
            email: user.contact.email
          }
        }
        const pagarme = await pagarmeConnect()
        message.info('Mais um minuto...')
        pagarme.subscriptions.create(subscription)
          .then(saveOnFirebase)
          .catch(defaultCatch)
      },
      error: defaultCatch,
      close: () => setLoading(false)
    })


    const params = {
      planId: myPlan.id,
      amount: finalAmount,
      uiColor: '#C44355',
      customerData: 'false',
      createToken: 'false',
      interestRate: 10,
      paymentMethods: 'credit_card'
    }

    checkout.open(params)
  }


  const saveOnFirebase = async subscription => {
    message.destroy()
    message.success('Compra efetuada com sucesso!')

    dispatch(unlockSidebar())
    setLoading(false)
    setSubscription(subscription.id)
    try {
      await trainerCollection
        .doc(userId())
        .update({ myPlan: subscription.plan.id, subscriptionId: subscription.id })

      await planSalesCollection
        .add({
          plan: subscription.plan.id,
          amount: subscription.plan.amount / 100,
          cardLastDigits: subscription.card_last_digits,
          customer: {
            name: subscription.customer.name,
            email: subscription.customer.email,
          },
          datetime: firestore.Timestamp.now(),
        })
    } catch (error) {
      defaultCatch(error)
    }

  }


  const selectPlan = plan => {
    setMyPlan({
      id: plan.id,
      amount: plan.amount
    })
  }

  const onClickCancelPlan = async () => {
    pagarmeConnect()
      .then(client => client.subscriptions.cancel({ id: subscription })
        .then(subscription => {
          trainerCollection
            .doc(userId())
            .update({ subscriptionId: null, myPlan: null })
            .then(() => {
              message.success('Cancelamento efetuado com sucesso!')
              setMyPlan({})
              setSubscription()
              dispatch(lockSidebar())
            })
        })
      )
      .catch(err => {
        message.error('Algo deu errado, tente novamente mais tarde 😕', 5)
      })
  }

  return (
    <Form>
      <Row>
        <Col span={24}>
          <MainHeader colored>Meu plano</MainHeader>
        </Col>
        <Col span={21}>
          <SecondHeader>Planos Movase.app</SecondHeader>
        </Col>
        {/* <ColStyled>
          <Button
            type='primary'
            icon='plus'
            onClick={() => setShowModal(!showModal)}
          >
            Novo
					</Button>
        </ColStyled> */}
        <Col span={24}>
          <h3>
            <strong>Contrate um novo plano e mova-se ainda mais :)</strong>
          </h3>
        </Col>
      </Row>
      <Row gutter={5}>
        {plansList.map(plan => {
          return (
            <Col key={plan.id} md={6} sm={24}>
              <CardStyled
                bordered
                actions={[
                  plan.id === myPlan.id
                    ? <Button disabled key='selected'>Selecionado</Button>
                    : (
                      <ButtonColor
                        color='green'
                        key='contract'
                        onClick={() => selectPlan(plan)}
                      >
                        Selecionar
                      </ButtonColor>
                    )
                ]}
              >
                <Description>{plan.name}</Description>
                {/* <Description>
                {plan.description}
              </Description>
              <MaxStudents>
                {plan.maxStudents} Alunos
              </MaxStudents> */}
                <Price>{formatToDecimal(plan.amount)[0]}</Price>
                <MinorText align='top'>{formatToDecimal(plan.amount)[1]}</MinorText>
                <MinorText align='baseline'>mensais</MinorText>
              </CardStyled>
            </Col>
          )
        }
        )}
      </Row>
      <Row type='flex' justify='space-between'>
        <Popconfirm
          title='Confirma cancelar assinatura?'
          onConfirm={onClickCancelPlan}
          okText='Sim'
          cancelText='Não'
          disabled={!subscription}
        >
          <Button
            type='dashed'
            icon='delete'
            disabled={!subscription}
          >
            Cancelar plano
          </Button>
        </Popconfirm>

        <Button
          type='primary'
          onClick={submit}
          disabled={!myPlan.id}
          loading={loading}
          icon='check'
        >
          Adquirir
					</Button>
      </Row>
      {/* <InnerNewPlan
        visible={showModal}
        onCancel={success => {
          if (success) getPlans()
          setShowModal(false)
        }}
      /> */}
    </Form>
  )
}

export default MyPlan
