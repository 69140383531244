import React, { useState, useEffect } from 'react'
import { withFormik } from 'formik'
import styled from '@emotion/styled'
import { methodsCollection, } from '../../../firebase'

import yup, { yupName, yupAmount, yupRequired } from '../../../utils/yupMessages'
import { getBase64, uploadB64AndReturnUrl, userId } from '../../../utils/helpers'
import { maxImageSize } from '../../../utils/constants'

import { Form, Row, Col, Input, Divider, Table, Button, message, Radio, Upload, Icon, Switch, Tooltip } from 'antd'
import { FormItem, FormItemAmount, RemoveEditButtons, SearchRemote, Headers } from '../../../components'

import FormFooter from './FormFooter'
import NewClass from './NewClass'

const { SecondHeader } = Headers
const { Column } = Table

const methodSummaryPlaceholder =
  'Esse método é para MOVERS que pretendem adquirir habilidades para escalar paredes e equilibrar sobre obstáculos...'

const RowMargin = styled(Row)`
	margin: 10px 0px 30px 0px;
`

const FormLeft = styled(Form)`
	text-align: left;	
`

const MaxChars = styled.p`
	text-align: right;
`

const MethodForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    amount: '',
    time: '',
    description: '',
    lessons: [],
    students: [],
    typeMethod: 'generico',
    isActive: true,
  }),

  validationSchema: yup.object().shape({
    name: yupName().max(55, 'Informe até 55 caracteres'),
    amount: yupAmount(),
    description: yupRequired('o resumo'),
    image: yupRequired('a imagem'),
  }),

  handleSubmit: async (_values, { setSubmitting }) => {
    const { key, ...values } = _values
    const times = _values.lessons.map(x => x.time).reduce((a, b) => a + b, 0)

    const id = key || methodsCollection.doc().id
    values.personal = userId()
    values.time = times

    try {
      await methodsCollection.doc(id).set(values)

      const text = id ? 'editado' : 'criado'
      message.success(`Método ${text} com sucesso!`)
      setSubmitting(false)

    } catch (error) {
      message.error('Ops, acontenceu um erro. Verifique os dados e tente novamente')
      console.error(error)
    }
  },
})


const InnerForm = props => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
    valuesToEdit,
    errors,
  } = props

  const { description, lessons, typeMethod, students } = values
  const [openNewClass, setOpenNewClass] = useState(false)
  const [valuesEdition, setValuesEdition] = useState({})

  useEffect(() => {
    if (valuesToEdit) {
      props.setValues(valuesToEdit.values)
    }

    return () => props.resetForm()
  }, [props.shouldRerender])


  const closeNewClass = value => {
    if (value) {
      const newValue = { ...value }
      if (value.index === undefined) {
        delete newValue.index
        const id = new Date().valueOf().toString()
        const lesson = { ...newValue, id }

        setFieldValue('lessons', [...lessons, lesson])
      } else {
        lessons[value.index] = newValue
      }

    }
    setOpenNewClass(false)
    setValuesEdition({})
  }


  const editClass = (values, index) => {
    setValuesEdition({ ...values, index })
    setOpenNewClass(true)
  }


  const removeClass = index => {
    lessons.splice(index, 1)
    setFieldValue('lessons', lessons)
  }


  const getStudents = arr => setFieldValue('students', arr)


  const changeRadio = ev => {
    const { value } = ev.target
    if (value === 'generico')
      setFieldValue('students', [])
    setFieldValue('typeMethod', value)
  }


  const changeImage = info => {
    const { name, uid, status, originFileObj } = info.file

    if (status === 'removed') {
      setFieldValue('image', '')
      return
    }

    getBase64(originFileObj, async image => {
      const url = await uploadB64AndReturnUrl(image, 'image')
      setFieldValue('image', { url, name, uid })
    })
  }


  const beforeUploadResume = file => {
    const fileIsGreatherThanMax = file.size > maxImageSize
    if (fileIsGreatherThanMax)
      message.error('O arquivo deve ser menor que 2MB!')

    return !fileIsGreatherThanMax
  }

  return (
    <FormLeft onSubmit={handleSubmit}>
      <Row>
        <Col span={24}>
          <h2><strong>Dados</strong></h2>
        </Col>
        <Row gutter={10}>
          <Col md={13}>
            <FormItem
              label='Nome'
              name='name'
              placeholder='Digite o nome do seu método'
              required
            />
          </Col>
          <Col md={4}>
            <FormItemAmount
              label='Valor total'
              name='amount'
              onChange={val => setFieldValue('amount', val)}
              required
            />
          </Col>

          <Col md={3}>
            <Form.Item name='image' label='Imagem' colon={false} required>
              <Upload
                name='file'
                action='https://yoficina-api.now.sh/customers'
                multiple={false}
                onChange={changeImage}
                fileList={values.image ? [values.image] : ''}
                openFileDialogOnClick
                beforeUpload={beforeUploadResume}
              >
                <Button disabled={!!values.image} type={errors.image ? 'danger' : 'default'}>
                  <Icon type='upload' /> Anexar
								</Button>
              </Upload>
              <div className='ant-form-explain'>
                <span style={{ color: '#f5222d' }}>
                  {errors.image}
                </span>
              </div>
            </Form.Item>
          </Col>

          <Col md={3} style={{ marginTop: 30 }}>
            Ativo {' '}
            <Switch
              checkedChildren={<Icon type='check' />}
              unCheckedChildren={<Icon type='close' />}
              checked={values.isActive}
              onChange={checked => setFieldValue('isActive', checked)}
            />
          </Col>

        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label='Tipo do método' colon={false}>
              <Radio.Group
                value={typeMethod}
                onChange={changeRadio}
                buttonStyle='solid'
              >
                <Radio.Button value='generico'>
                  <Tooltip title='Todos que tem acesso ao meu app podem ver o conteúdo'>
                    Genérico
                  </Tooltip></Radio.Button>
                <Radio.Button value='especifico'>
                  <Tooltip title='Apenas alunos autorizados por mim podem ver este conteúdo'>
                    Específico
                </Tooltip>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          {typeMethod === 'especifico' && (
            <Col span={24}>
              <SearchRemote
                setValues={getStudents}
                values={students}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              label='Resumo'
              name='description'
              component={Input.TextArea}
              placeholder={methodSummaryPlaceholder}
              rows={10}
              maxLength={300}
              required
            />
            <MaxChars>
              Total de caracteres restantes: {300 - description.length}
            </MaxChars>
          </Col>
        </Row>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row gutter={10}>
        <Col md={20}>
          <SecondHeader>Aulas</SecondHeader>
        </Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          <Button
            type='default'
            onClick={() => setOpenNewClass(true)}
            icon='plus'
          >
            Nova aula
					</Button>
          <NewClass
            visible={openNewClass}
            valuesEdition={valuesEdition}
            onCancel={closeNewClass}
          />
        </Col>
        <Col md={24}>
          <Table
            dataSource={lessons}
            rowKey='uid'
            locale={{ emptyText: 'Nenhum registro :(' }}
            pagination={false}
          >
            <Column
              title='Nome da aula'
              dataIndex='name'
              key='name'
            />
            <Column
              title='Descrição da aula'
              render={(_, val) => `${val.description.slice(0, 70)}...`}
              dataIndex='description'
              key='description'
            />
            <Column
              title='Tempo médio'
              dataIndex='time'
              render={(_, val) => `${val.time / 60} minuto(s)`}
            />
            <Column
              title='Ações'
              dataIndex='actions'
              align='center'
              width='20%'
              render={(_, values, index) => (
                <RemoveEditButtons
                  onEdit={() => editClass(values, index)}
                  onRemove={() => removeClass(index)}
                />
              )}
            />
          </Table>
        </Col>
      </Row>
      <RowMargin>
        <FormFooter
          isValid={isValid}
          isSubmitting={isSubmitting}
          goBack={props.history.goBack}
        />
      </RowMargin>
    </FormLeft>
  )
}

export default MethodForm(InnerForm)

