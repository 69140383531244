import * as yup from 'yup'
import { withFormik } from 'formik'
import { firebaseErrorHandler } from '../../utils/helpers'
import { firebaseAuth, trainerCollection } from '../../firebase'
import { yupName, yupEmail, yupPassword } from '../../utils/yupMessages'
import { message } from 'antd'

const schema = yup.object().shape({
  name: yupName(),
  contact: yup.object({
    email: yupEmail()
  }),
  password: yupPassword(),
  confirm: yup.string()
    .oneOf([yup.ref('password')], 'Senhas devem são iguais')
    .required('Informe a confirmação')
})

const RegisterFormik = withFormik({
  mapPropsToValues: () => ({
    name: '',
    contact: {
      email: '',
    },
    password: '',
    confirm: '',
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const { contact, password } = values

    try {
      const authData = await firebaseAuth
        .createUserWithEmailAndPassword(contact.email, password)
      delete values.password
      delete values.confirm
      await trainerCollection.doc(authData.user.uid).set(values)
      props.history.push('/')
      message.success('Seja bem vindo!')
    } catch (err) {
      firebaseErrorHandler(err)
      setSubmitting(false)
    }
  }
})

export default RegisterFormik
