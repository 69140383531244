import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Upload, Icon, message, Button, Input } from 'antd'
import { FormItem, MaskedInput, Loader, Headers } from '../../components'

import { firebaseAuth, userData } from '../../firebase'
import { uploadB64AndReturnUrl, defaultCatch, getBase64 } from '../../utils/helpers'
import { maxImageSize } from '../../utils/constants'

import FormFooter from '../Methods/Form/FormFooter'
import { ErrorMessage } from 'formik'
import Biography from './biographyFormik'

const { MainHeader, SecondHeader } = Headers

const BiographyForm = props => {
  const {
    values,
    setFieldValue,
    isValid,
    isSubmitting,
    handleSubmit,
    history,
  } = props

  const [isLoadingFile, setFileLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    getUserData()
  }, [])


  const getUserData = async () => {
    setLoading(true)
    const values = await userData()

    if (values && values.biography)
      props.setValues(values.biography)

    setLoading(false)

    setImageUrl(firebaseAuth.currentUser.photoURL)
  }

  const handleChange = info => {
    const { originFileObj, status } = info.file
    if (status === 'uploading') {
      setFileLoading(true)
      return
    }
    if (status === 'done') {
      getBase64(originFileObj, imageUrl => {
        setImageUrl(imageUrl)
        setFileLoading(false)
        sendAvatarToFirebase(imageUrl)
      })
    }
  }


  const sendAvatarToFirebase = async imageUrl => {
    try {
      const photoURL = await uploadB64AndReturnUrl(imageUrl, 'avatars')
      firebaseAuth.currentUser.updateProfile({ photoURL })
      setFieldValue('isAvatarAttached', true, true)
    } catch (error) {
      defaultCatch(error)
      setFieldValue('isAvatarAttached', false)
      setImageUrl('')
    }
  }


  const beforeUploadAvatar = file => {
    const isImage = ['jpeg', 'jpg', 'png'].includes(file.type.split('/')[1])

    if (!isImage) {
      message.error('Por favor, suba uma imagem JPEG ou PNG :)', 3)
    }

    const fileIsGreatherThanMax = file.size > maxImageSize
    if (fileIsGreatherThanMax) {
      message.error('A imagem deve ser menor que 2MB!')
    }

    return isImage && !fileIsGreatherThanMax
  }


  const changeResume = info => {
    const { name, uid, status, originFileObj } = info.file

    if (status === 'removed') {
      setFieldValue('resume', '')
      return
    }

    getBase64(originFileObj, async resume => {
      const url = await uploadB64AndReturnUrl(resume, 'resume')
      setFieldValue('resume', { url, name, uid })
    })
  }


  const beforeUploadResume = file => {
    const fileIsGreatherThanMax = file.size > maxImageSize
    if (fileIsGreatherThanMax)
      message.error('O arquivo deve ser menor que 2MB!')

    return !fileIsGreatherThanMax
  }


  return (
    <Loader status={loading}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={24}>
            <MainHeader colored>Biografia</MainHeader>
          </Col>
          <Col span={24}>
            <SecondHeader>Dados Pessoais</SecondHeader>
          </Col>

          <Row gutter={10}>
            <Col md={3}>
              <Form.Item
                label='Foto do Perfil'
                name='isAvatarAttached'
                colon={false}
                required
              >
                <div className='clearfix'>
                  <Upload
                    name='avatar'
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    action='https://yoficina-api.now.sh/customers'
                    beforeUpload={beforeUploadAvatar}
                    onChange={handleChange}
                  >

                    {imageUrl ? (
                      <img src={imageUrl} alt='avatar' width={100} height={100} />
                    ) : (
                        <div>
                          <Icon type={isLoadingFile ? 'loading' : 'plus'} />
                          <div className='ant-upload-text'>Subir foto</div>
                        </div>
                      )
                    }
                  </Upload>
                </div>
                <ErrorMessage name='isAvatarAttached' />
              </Form.Item>
            </Col>

            <Col md={21}>
              <Col md={8}>
                <FormItem
                  label='Nome'
                  name='name'
                  placeholder='Digite o seu nome e/ou nome artístico'
                  required
                />
              </Col>

              <Col md={11}>
                <FormItem
                  label='Cidade/Estado'
                  name='locations'
                  placeholder='Digite sua cidade de atuação'
                  required
                />
              </Col>
              <Col md={4}>

                <Form.Item name='resume' label='Currículo'>
                  <Upload
                    name='file'
                    action='https://yoficina-api.now.sh/customers'
                    multiple={false}
                    onChange={changeResume}
                    fileList={values.resume ? [values.resume] : ''}
                    openFileDialogOnClick
                    beforeUpload={beforeUploadResume}
                  >
                    <Button disabled={!!values.resume}>
                      <Icon type='upload' /> Anexar meu currículo
										</Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col md={24}>
                <FormItem
                  name='biographyText'
                  label='Biografia'
                  placeholder='O que te faz mover? O que você poderá proporcionar para outras pessoas?'
                  component={Input.TextArea}
                  maxLength={300}
                  rows={6}
                />
              </Col>

            </Col>
          </Row>
        </Row>
        <Row>

          <Col span={24}>
            <SecondHeader>
              Formas de Contato
						</SecondHeader>
          </Col>
          <Row gutter={10}>
            <Col md={5}>
              <FormItem
                name='contacts.facebook'
                label='Facebook'
                placeholder='batman.movaseapp'
              />
            </Col>

            <Col md={4}>
              <FormItem
                name='contacts.whatsapp'
                label='WhatsApp'
                mask='(99)99999-9999'
                placeholder='(99)99999-9999'
                component={MaskedInput}
              />
            </Col>
            <Col md={5}>
              <FormItem
                name='contacts.email'
                label='Email'
                placeholder='batman@movaseapp.com'
              />
            </Col>
            <Col md={5}>
              <FormItem
                name='contacts.instagram'
                label='Instagram'
                placeholder='batmandomovaseapp'
              />
            </Col>
            <Col md={5}>
              <FormItem
                name='contacts.twitter'
                label='Twitter'
                placeholder='batmandomovaseapp'
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <FormFooter
            isValid={isValid}
            isSubmitting={isSubmitting}
            goBack={history && history.goBack}
          />
        </Row>
      </Form>
    </Loader>
  )
}

export default Biography(BiographyForm)
