import dayjs from 'dayjs'
import { defaultCatch } from '../../utils/helpers'
import { planSalesCollection, userData } from '../../firebase'

export const getSales = async planList => {
  try {
    const user = await userData()
    const formatDate = date => dayjs(date).format('DD [de] MMMM [de] YYYY [às] HH:ss')
    const res = (await planSalesCollection.get()).docs

    const sales = res.map(item => {
      const sale = item.data()
      const plan = planList.find(plan => plan.id === user.myPlan)
      return {
        ...sale,
        id: item.id,
        datetime: formatDate(sale.datetime.toDate()),
        plan,
      }
    })

    return sales
  } catch (error) {
    defaultCatch(error)
    return []
  }

}
