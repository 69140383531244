import React from 'react'
import { FormItem, InputNumber } from '.'

const FormItemAmount = props => {
	return (
		<FormItem
			component={InputNumber}
			precision={2}
			decimalSeparator=','
			min={0}
			{...props}
		/>
	)
}

export default FormItemAmount
