import React, { useEffect, useState } from 'react'

import { Col } from 'antd'
import { Headers } from '../../components'
import Title from 'antd/lib/typography/Title'
import Table from '../../components/Table'
import Column from 'antd/lib/table/Column'
import { getSales } from './services'
import { useSelector } from 'react-redux'

const { MainHeader } = Headers

const MyFinances = () => {
  const [sales, setSales] = useState([])
  const [loading, setLoading] = useState(true)

  const planList = useSelector(({ global }) => global.plans)

  useEffect(() => {

    getSales(planList)
      .then(setSales)
      .catch(() => setLoading(false))
      .finally(() => {
        if (planList.length)
          setLoading(false)
      })

  }, [planList])

  const total = sales.map(x => x.amount).reduce((a, b) => a + b, 0)

  return (
    <Col>
      <Col span={24}>
        <MainHeader colored>
          Minhas Finanças
				</MainHeader>
      </Col>
      <Col>
        <Title style={{ textAlign: 'center' }} level={3}>Pagamento confirmado:</Title>
        <Title style={{ textAlign: 'center' }} level={2}>R$ {total}</Title>
      </Col>
      <Col span={24}>
        <Table
          dataSource={sales}
          loading={loading}
          rowKey='id'
          bordered
        >
          <Column
            title='Nome do cliente'
            dataIndex='customer.name'
          />
          <Column
            title='Email do cliente'
            dataIndex='customer.email'
          />
          <Column
            title='Método adquirido'
            dataIndex='plan.name'
          />
          <Column
            title='Valor pago'
            dataIndex='amount'
            render={val => `R$ ${val}`}
          />
          <Column
            title='Data e hora'
            dataIndex='datetime'
          />
        </Table>
      </Col>
    </Col>
  )
}

export default MyFinances
