import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Row, Col } from 'antd'
import { Headers, Table } from '../../components'
import Column from 'antd/lib/table/Column'
import Search from 'antd/lib/input/Search'

import { studentsCollection } from '../../firebase'

const { SecondHeader, MainHeader } = Headers

const ColMargin = styled(Col)`
	margin-bottom: 1em;
`

const MyStudents = () => {

  const [students, setStudents] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const search = async text => {
    setIsLoading(true)
    try {
      const data = await studentsCollection
        .orderBy('searchableName')
        .startAt(text)
        .endAt(`${text}\uf8ff`)
        .get()

      feedTable(data)
    } catch (error) {
      console.error(error)
      setIsLoading(true)
    }
  }


  const feedTable = data => {
    const students = []
    data.forEach(x => students.push({ ...x.data(), key: x.id }))
    setStudents(students)
    setIsLoading(false)
  }


  return (
    <Row>
      <Col span={24}>
        <MainHeader colored>Meus Alunos</MainHeader>
      </Col>
      <Col span={24}>
        <SecondHeader>Listagem</SecondHeader>
      </Col>
      <Row gutter={10}>
        <ColMargin md={24}>
          <Search
            placeholder='Digite o nome do método'
            enterButton='Pesquisar'
            onSearch={search}
          />
        </ColMargin>
        <Col md={24}>
          <Table
            dataSource={students}
            loading={isLoading}
          >
            <Column
              title='Nome'
              dataIndex='name'
            />
            <Column
              title='Email'
              dataIndex='email'
            />
          </Table>
        </Col>
      </Row>
    </Row>
  )
}

export default MyStudents
