import pagarmeAPI from 'pagarme'
import { api_key } from '../utils/constants'
import { studentsCollection } from '../firebase'
import { removeAcentos, defaultCatch } from '../utils/helpers'
import axios from 'axios'

export const pagarmeConnect = () => pagarmeAPI.client.connect({ api_key })

export const search = async (text = '', field) => {
  const searchableText = removeAcentos(text.toLowerCase())
  const arr = []
  const data = await studentsCollection
    .orderBy(field)
    .startAt(searchableText)
    .endAt(`${searchableText}\uf8ff`)
    .get()

  if (data.empty) return arr

  data.forEach(x => arr.push({ id: x.id, ...x.data() }))
  return arr
}


export const sendPublicationEmail = (values, user) => {
  try {
    axios.post('https://us-central1-movase-a571b.cloudfunctions.net/sendEmailPublication', {
      subject: `Dados de publicação de ${user}`,
      body: JSON.stringify(values)
    })
    return true
  } catch (error) {
    console.error(error)
  }
}


export const requestPlans = async () => {
  try {
    const pagarme = await pagarmeConnect()
    const allPlans = await pagarme.plans.all()
    const plans = allPlans.filter(plan => !plan.name.startsWith('INATIVO'))
    return plans
  } catch (error) {
    defaultCatch(error)
    return []
  }
}
