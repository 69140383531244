import React, { useState } from 'react'
import { Row, Col, Tabs } from 'antd'
import MethodForm from './Form/Form'
import MethodList from './List/List'
import { Headers } from '../../components'

const { TabPane } = Tabs
const { MainHeader } = Headers

const Method = props => {
	const [shouldRerender, setShouldRerender] = useState(true)
	const [valuesToEdit, setValuesToEdit] = useState()
	const [tabKey, setTabKey] = useState('2')

	const editMethod = (values, index) => {
		setShouldRerender(!shouldRerender)
		setValuesToEdit({ values, index })
		setTabKey('2')
	}

	const changeTab = key => {
		setTabKey(key)
		setValuesToEdit()
		setShouldRerender(!shouldRerender)
	}

	return (
		<Row>
			<Col>
				<MainHeader colored>
					Cadastro de Métodos
				</MainHeader>
			</Col>
			<Row gutter={10}>
				<Col md={24}>
					<Tabs
						activeKey={tabKey}
						defaultActiveKey={tabKey}
						onChange={changeTab}
					>
						<TabPane tab='Consultar' key='1'>
							<MethodList
								valuesToEdit={editMethod}
								shouldRerender={shouldRerender}
							/>
						</TabPane>
						<TabPane tab='Cadastrar/Editar' key='2'>
							<MethodForm
								{...props}
								valuesToEdit={valuesToEdit}
								shouldRerender={shouldRerender}
							/>
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</Row>
	)
}

export default Method
