import React from 'react'
import {
  Login,
  Register,
  Biography,
  Methods,
  MyPlan,
  Students,
  Finances,
  AppInformations,
  Main,
  Admin
} from './Pages'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { firebaseAuth } from './firebase'

const Routes = () => (
  <Router>
    <Switch>
      <AdminRoute path='/admin' component={Admin} />
      <Route path='/login' component={Login} />
      <Route path='/registrar' component={Register} />
      <PrivateRoute path='/biografia' component={Biography} />
      <PrivateRoute path='/metodos/(cadastro|edicao)' component={Methods} />
      <PrivateRoute path='/meu-plano' component={MyPlan} />
      <PrivateRoute path='/meus-alunos' component={Students} />
      <PrivateRoute path='/minhas-financas' component={Finances} />
      <PrivateRoute path='/informacoes_app' component={AppInformations} />
      <PrivateRoute path='/' exact component={MyPlan} />
    </Switch>
  </Router>
)


const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    firebaseAuth.currentUser && firebaseAuth.currentUser.uid ? (
      <Route
        {...rest}
        render={props => <Component {...props} />}
      />
    ) : (
        <Redirect to='/login' />
      )
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    firebaseAuth.currentUser ? (
      <Main>
        <Route
          {...rest}
          render={props => <Component {...props} />}
        />
      </Main>
    ) : (
        <Redirect to='/login' />
      )
  )
}


export default Routes
