

import React from 'react'
import { Row, Col } from 'antd'
import Flex from './Flex'

import image from '../assets/logo.png'
import styled from '@emotion/styled'


const Text = styled.span`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: black;
  line-height: 1em;
  margin-bottom: ${p => p.isLast ? '30px' : 0};
`

const Image = styled.img`
  width: 200px;
  height: 200;
  align-self: center;
  margin-bottom: 30px;
`


const AuthHeader = ({ children }) => {
  return (
    <Row
      type='flex'
      justify='center'
      align='middle'
      style={{ flexDirection: 'column', minHeight: '100vh' }}
    >
      <Col lg={7} md={20} xs={24}>
        <Flex>
          <Image src={image} alt='logo' />
          <Text>Apresente-se.</Text>
          <Text>Inspire-se.</Text>
          <Text isLast>Movase-se.</Text>
          {children}
        </Flex>
      </Col>
    </Row>
  )
}

export default AuthHeader
