import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'

import { Layout } from 'antd'
import { firebaseAuth } from './firebase'

import Routes from './routes'
import store from './store'
import 'dayjs/locale/pt-br'
import dayjs from 'dayjs'

dayjs.locale('pt-br')

const { Content } = Layout


const App = () => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false)

  useEffect(() => {
    const listener = onAuthChange()
    return () => listener()
  }, [])

  const onAuthChange = () =>
    firebaseAuth.onAuthStateChanged(user => setAlreadyLoaded(user))

  if (alreadyLoaded === false)
    return 'Quase lá ...'


  return (
    <Provider store={store}>
      <Layout>
        <Content>
          <Routes />
        </Content>
      </Layout>
    </Provider>
  )
}


export default App
