import React from 'react'
import PropTypes from 'prop-types'

import { Col, Button } from 'antd'

import styled from '@emotion/styled'


const StyledCol = styled(Col)`
	text-align: right;
`

const StyledButton = styled(Button)`
	margin-right: 8px;
`

const FormFooter = props => {
  const { isSubmitting, goBack } = props
  return (
    <StyledCol md={24}>
      <StyledButton
        onClick={goBack}
        icon='left'
      >
        Voltar
			</StyledButton>

      <Button
        type='primary'
        htmlType='submit'
        loading={isSubmitting}
        icon='check'
      >
        Salvar
			</Button>
    </StyledCol>
  )
}

FormFooter.propTypes = {
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  goBack: PropTypes.func
}

export default FormFooter
