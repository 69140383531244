import * as yup from 'yup'

export default yup

export const yupName = () =>
  yup.string()
    .trim()
    .required('Informe o nome')

export const yupDescription = () =>
  yup.string()
    .trim()
    .required('Informe a descrição')
    .nullable()

export const yupEmail = () =>
  yup.string()
    .required('Informe o email')
    .email('Email inválido')

export const yupPassword = () =>
  yup.string()
    .trim()
    .required('Informe a senha')

export const yupAmount = () =>
  yup.string()
    .required('Informe o valor')
    .nullable()

export const yupRequired = message =>
  yup.string()
    .trim()
    .required(`Informe ${message}`)

export const yupLinkWithoutAt = () =>
  yup.string().test(
    'has-not-at',
    'Ops! Somente texto, sem @',
    value => {
      if (value) {
        return !value.includes('@')
      }

      return true
    }
  )

