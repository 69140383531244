import React from 'react'
import { Spin } from 'antd'

const Loader = ({ children, status }) => {
	return (
		<Spin spinning={status} tip='Aguarde ...'>
			{children}
		</Spin>
	)
}

export default Loader
