import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-storage'
import 'firebase/firebase-auth'
import { userId } from '../utils/helpers'

const config = {
  apiKey: 'AIzaSyDCrKjwiwE5EEihVWXSBZ4tEfcJat0VJZM',
  authDomain: 'movase-a571b.firebaseapp.com',
  databaseURL: 'https://movase-a571b.firebaseio.com',
  projectId: 'movase-a571b',
  storageBucket: 'movase-a571b.appspot.com',
  messagingSenderId: '466252342939'
}

firebase.initializeApp(config)
export default firebase

export const db = firebase.firestore()

export const firebaseAuth = firebase.auth()
export const firestore = firebase.firestore
export const firebaseStorageRef = firebase.storage().ref()
export const trainerCollection = db.collection('trainer')
export const planSalesCollection = db.collection('planSales')
export const methodsCollection = db.collection('methods')
export const studentsCollection = db.collection('students')
export const salesCollection = db.collection('sales')

const { LOCAL } = firebase.auth.Auth.Persistence
firebaseAuth.setPersistence(LOCAL)

export const userData = async () => {
  try {
    const data = await trainerCollection.doc(userId()).get()
    if (data)
      return data.data()

  } catch (error) {
    console.error(`@ERROR! ${error}`)
  }
}
