import React from 'react'
import InputMasked from 'react-input-mask'
import PropTypes from 'prop-types'

const InputMask = props => {
	const { disabled, ...rest } = props

	const className = disabled ? 'ant-input-disabled ant-input' : 'ant-input'

	return (
		<InputMasked
			maskChar=''
			className={className}
			{...rest}
		/>
	)
}
InputMask.propTypes = {
	disabled: PropTypes.bool
}

export default InputMask
