import { message } from 'antd'
import { firebaseStorageRef, firebaseAuth } from '../firebase'
import getValue from 'get-value'


export const get = (obj, path, defaultValue = '') => getValue(obj, path, defaultValue)


export const userId = () => firebaseAuth.currentUser.uid


export const firebaseErrorHandler = err => {
  let msg
  console.error(err)
  switch (err.code) {
    case 'auth/wrong-password':
      msg = 'Usuário ou senha inválido'
      break
    case 'auth/user-not-found':
      msg = 'Usuário ou senha inválido'
      break
    case 'auth/invalid-email':
      msg = 'Email inválido'
      break
    case 'auth/email-already-in-use':
      msg = 'Email já está em uso'
      break
    default:
      msg = ''
  }

  if (msg)
    message.error(msg)
}


export const uploadB64AndReturnUrl = async (base64, child) => {
  const { ref } = await firebaseStorageRef
    .child(`${child}/${userId()}`)
    .putString(base64, 'data_url')

  const imageUrl = await ref.getDownloadURL()
  return imageUrl
}


export const defaultCatch = error => {
  message.error('Algo deu errado, tente novamente :(')
  console.error(error)
}


export const replaceDotForComma = amount => {
  return amount.toFixed(2).replace('.', ',')
}


export const formatToDecimal = amount => {
  const [c1, c2, ...real] = amount.toString().split('').reverse()
  const cents = `${c2}${c1}`
  const realFormatted = real.reverse().join('') || '00'

  return [realFormatted, cents]
}


export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}


export const removeAcentos = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
