import React from 'react'
import PropTypes from 'prop-types'
import get from 'get-value'
import { Field, ErrorMessage } from 'formik'
import { Form, Input } from 'antd'

export const FormItem = props => {
  const {
    label,
    name,
    required,
    children,
    component: Component = Input,
    ...rest
  } = props

  const formItemProps = { label, required }
  return (
    <Field name={name}>
      {({ field, form }) => {

        const validateStatus = () =>
          get(form, `touched.${name}`)
            ? get(form, `errors.${name}`) ? 'error' : 'success'
            : ''

        if (required)
          formItemProps.validateStatus = validateStatus(name)

        return (
          <Form.Item
            {...formItemProps}
            help={<ErrorMessage name={name} />}
            colon={false}
          >
            <Component {...field} {...rest} />
          </Form.Item>
        )
      }}
    </Field>
  )
}

FormItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validateStatus: PropTypes.string,
  placeholder: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  required: PropTypes.bool
}

FormItem.defaultProps = {
  required: false
}

export default FormItem
