import React from 'react'

import { Form, Row, Button, Col, Input } from 'antd'
import { FormItem, MaskedInput, AuthHeader } from '../../components'
import Register from './RegisterFormik'

const RegisterForm = props => {
  const { isSubmitting, handleSubmit } = props

  return (
    <AuthHeader>
      <Form onSubmit={handleSubmit}>
        <Row type='flex' justify='center'>
          <Col md={12} lg={14}>

            <FormItem
              name='name'
              label='Nome'
              required
            />

            <FormItem
              name='contact.email'
              label='Email'
              required
            />

            <Row gutter={10}>
              <Col xs={24} md={12} lg={12}>
                <FormItem
                  name='contact.telephone'
                  label='Telefone'
                  mask='(99)9999-9999'
                  component={MaskedInput}
                />
              </Col>

              <Col xs={24} md={12} lg={12}>
                <FormItem
                  name='contact.cellphone'
                  label='Celular'
                  mask='(99)99999-9999'
                  component={MaskedInput}
                />
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} md={12} lg={12}>
                <FormItem
                  name='password'
                  label='Senha'
                  component={Input.Password}
                  visibilityToggle
                  required
                />
              </Col>

              <Col xs={24} md={12} lg={12}>
                <FormItem
                  name='confirm'
                  label='Confirme a Senha'
                  component={Input.Password}
                  visibilityToggle
                  required
                />
              </Col>
            </Row>

          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col md={12} lg={14}>
            <Row type='flex' justify='space-between'>

              <Button
                onClick={() => props.history.goBack()}
                icon='left'
                style={{ marginRight: 10 }}
              >
                Voltar
              </Button>

              <Button
                type='primary'
                htmlType='submit'
                loading={isSubmitting}
                icon='check'
              >
                Mover-se
								</Button>

            </Row>
          </Col>
        </Row>
      </Form>
    </AuthHeader>
  )
}

export default Register(RegisterForm)
