import React from 'react'
import { Table as ATable } from 'antd'
import styled from '@emotion/styled'

const StyledTable = styled(ATable)`
	margin: 1rem 0px;
	background-color: #FFF;
`

const Table = props => {
	return (
		<StyledTable
			locale={{ emptyText: 'Nenhum registro :(' }}
			pagination={false}
			{...props}
		/>
	)
}

export default Table
