import React from 'react'

const Admin = () => {
  return (
    <div>
      <h1>asdasd</h1>
    </div>
  )
}

export default Admin
