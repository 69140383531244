import React, { useState, useEffect } from 'react'

import { Col, Row, message } from 'antd'
import { Headers, RemoveEditButtons, Table } from '../../../components'
import Column from 'antd/lib/table/Column'
import Search from 'antd/lib/input/Search'

import { methodsCollection, } from '../../../firebase'
import { replaceDotForComma, userId } from '../../../utils/helpers'

const { SecondHeader } = Headers

const List = props => {
  const { shouldRerender } = props
  const [methods, setMethods] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    getUserData()
  }, [shouldRerender])


  const getUserData = async () => {
    setIsLoading(true)
    const data = await methodsCollection
      .where('personal', '==', userId())
      .get()
    feedTable(data)
  }


  const removeMethod = async ({ key }, index) => {
    await methodsCollection.doc(key).delete()
    setMethods(methods.filter((_, idx) => idx !== index))
    message.success('Método deletado com sucesso!')
  }


  const search = async text => {
    setIsLoading(true)
    const data = await methodsCollection
      .orderBy('name')
      .startAt(text)
      .endAt(`${text}\uf8ff`)
      .get()

    feedTable(data)
  }


  const feedTable = data => {
    const methods = []
    data.forEach(x => methods.push({ ...x.data(), key: x.id }))
    setMethods(methods)
    setIsLoading(false)
  }

  return (
    <Row>
      <Row>
        <Col span={24}>
          <SecondHeader>Listagem de Métodos</SecondHeader>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Search
            onSearch={search}
            placeholder='Digite o nome do método'
            enterButton='Pesquisar'
          />
        </Col>
        <Col md={24}>
          <Table
            dataSource={methods}
            loading={isLoading}
          >
            <Column
              title='Nome'
              dataIndex='name'
              key='name'
            />
            <Column
              title='Quantidade de aulas'
              dataIndex='amountLessons'
              key='amountLessons'
              render={(_, val) => `${val.lessons.length} aula(s)`}
            />
            <Column
              title='Tempo médio'
              dataIndex='time'
              render={(_, val) => `${val.time / 60} minuto(s)`}
            />
            <Column
              title='Valor'
              dataIndex='amount'
              render={(_, val) => `R$ ${replaceDotForComma(val.amount)}`}
            />
            <Column
              title='Ações'
              dataIndex='actions'
              align='center'
              width='20%'
              render={(_, values, index) => (
                <RemoveEditButtons
                  onEdit={() => props.valuesToEdit(values, index)}
                  onRemove={() => removeMethod(values, index)}
                />
              )}
            />
          </Table>
        </Col>
      </Row>
    </Row>
  )
}

export default List
