import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, message, Upload, Button, Icon } from 'antd'
import { MainHeader, SecondHeader } from '../../components/Headers'
import { FormItem, Loader } from '../../components'
import { withFormik } from 'formik'

import styled from '@emotion/styled'
import { trainerCollection, userData } from '../../firebase'
import { userId, defaultCatch, getBase64, uploadB64AndReturnUrl } from '../../utils/helpers'
import FormFooter from '../Methods/Form/FormFooter'
import yup, { yupRequired } from '../../utils/yupMessages'
import { sendPublicationEmail } from '../../services'
import { maxImageSize } from '../../utils/constants'

const MaxChars = styled.p`
  text-align: right;
  margin-top: -17px;
`

const schema = yup.object({
  title: yupRequired('Informe o título'),
  shortDescription: yupRequired('Informe a descrição curta'),
  completeDescription: yupRequired('Informe a descrição completa'),
  logo: yupRequired('Insira um logo'),
})

const FormikWrapper = withFormik({
  mapPropsToValues: () => ({
    title: '',
    shortDescription: '',
    completeDescription: '',
    logo: ''
  }),
  validationSchema: schema,
  handleSubmit: async ({ user, ...values }, p) => {
    try {
      await trainerCollection
        .doc(userId())
        .update({ googlePlayData: values })

      sendPublicationEmail(values, user)

      message.success('Cadastro salvo!')
    } catch (error) {
      defaultCatch(error)
    }
    p.setSubmitting(false)
  }
})

const AppInformations = props => {
  const {
    values,
    isValid,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    history
  } = props

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getUserData = async () => {
      setLoading(true)
      const values = await userData()
      if (!values) return

      if (values.googlePlayData) {
        props.setValues(values.googlePlayData)
      }
      setFieldValue('user', values.name)
      setLoading(false)
    }

    getUserData()
  }, [])


  const beforeUpload = (file, field) => {
    const fileIsGreatherThanMax = file.size > maxImageSize
    if (fileIsGreatherThanMax)
      message.error('O arquivo deve ser menor que 2MB!')

    return !fileIsGreatherThanMax
  }


  const changeImage = (info, field) => {

    const { name, uid, status, originFileObj, type } = info.file
    if (status === 'removed') {
      setFieldValue(field, '')
      return
    }


    const [generic, especific] = type.split('/')
    if (generic !== 'image') {
      message.error('Por favor, anexe um arquivo de imagem')
      return
    }

    if (field === 'logo' && especific !== 'png') {
      message.error('Para o logo, é necessário uma imagem PNG')
      return
    }

    if (status === 'uploading')
      message.loading('Aguarde ...')

    getBase64(originFileObj, async image => {
      const url = await uploadB64AndReturnUrl(image, field)
      setFieldValue(field, { url, name, uid })
    })
  }


  return (
    <Loader status={loading}>
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col span={24}>
            <MainHeader colored>Textos para publicação</MainHeader>
          </Col>

          <Col span={24}>
            <SecondHeader>Play Store - Google</SecondHeader>
          </Col>

          <Row gutter={10}>
            <Col md={8}>

              <FormItem
                label='Título do aplicativo'
                name='title'
                required
              />

            </Col>
            <Col md={24}>

              <FormItem
                name='shortDescription'
                label='Descrição resumida'
                component={Input.TextArea}
                maxLength={80}
                rows={3}
                required
              />
              <MaxChars>
                Caracteres restantes: {' '}
                <strong>{80 - values.shortDescription.length}</strong>
              </MaxChars>

            </Col>
            <Col md={24}>

              <FormItem
                name='completeDescription'
                label='Descrição completa'
                component={Input.TextArea}
                maxLength={4000}
                rows={9}
                required
              />
              <MaxChars>
                Caracteres restantes: {' '}
                <strong>{4000 - values.completeDescription.length}</strong>
              </MaxChars>
            </Col>

          </Row>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md={3}>

            <Form.Item
              name='image'
              label='Logo'
              colon={false}
              help='Deve ser PNG - 512x512'
              required
            >
              <Upload
                name='file'
                onChange={v => changeImage(v, 'logo')}
                action='https://yoficina-api.now.sh/customers'
                fileList={values.logo ? [values.logo] : ''}
                beforeUpload={file => beforeUpload(file, 'logo')}
                multiple={false}
                openFileDialogOnClick
              >
                <Button disabled={!!values.logo}>
                  <Icon type='upload' /> Anexar
                </Button>
              </Upload>

            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={3}>

            <Form.Item
              name='image'
              label='Banner'
              colon={false}
              help='Deve ser 1024x500'
            >
              <Upload
                name='file'
                onChange={v => changeImage(v, 'banner')}
                action='https://yoficina-api.now.sh/customers'
                fileList={values.banner ? [values.banner] : ''}
                beforeUpload={file => beforeUpload(file, 'banner')}
                multiple={false}
                openFileDialogOnClick
              >
                <Button disabled={!!values.banner}>
                  <Icon type='upload' /> Anexar
                </Button>
              </Upload>
            </Form.Item>

          </Col>
        </Row>
        <Row>

          <FormFooter
            isValid={isValid}
            isSubmitting={isSubmitting}
            goBack={history && history.goBack}
          />

        </Row>
      </Form>
    </Loader>
  )
}

export default FormikWrapper(AppInformations)
