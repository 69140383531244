import { combineReducers, configureStore } from '@reduxjs/toolkit'
import global from './global'
import user from './user'

const rootReducer = combineReducers({
  global,
  user,
})

const store = configureStore({ reducer: rootReducer })


export default store
