import React from 'react'
import TextButton from './TextButton'
import { Divider, Popconfirm } from 'antd'
import PropTypes from 'prop-types'

const RemoveEditButtons = props => {
	const { onEdit, onRemove } = props
	return (
		<>
			<TextButton onClick={onEdit}>
				Editar
		</TextButton>
			<Divider type='vertical' />
			<Popconfirm
				title='Confirma a exclusão?'
				onConfirm={onRemove}
				okText='Sim'
				cancelText='Não'
			>
				<TextButton>Excluir</TextButton>
			</Popconfirm>
		</>
	)
}

RemoveEditButtons.propTypes = {
	onEdit: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
}

export default RemoveEditButtons

