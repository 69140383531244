import * as yup from 'yup'
import { withFormik } from 'formik'
import { trainerCollection, firebaseAuth } from '../../firebase'
import { message } from 'antd'
import { defaultCatch, userId } from '../../utils/helpers'
import { yupName, yupRequired, yupLinkWithoutAt } from '../../utils/yupMessages'

export const schema = yup.object().shape({
  name: yupName(),
  locations: yupRequired('a localização'),
  contacts: yup.object({
    email: yup.string().email('Email inválido'),
    instagram: yupLinkWithoutAt(),
    twitter: yupLinkWithoutAt()
  })
})

const Biography = withFormik({
  mapPropsToValues: () => ({ resume: '' }),
  validationSchema: schema,
  handleSubmit: async (values, { setSubmitting }) => {
    try {
      await firebaseAuth
        .currentUser
        .updateProfile({ displayName: values.name })

      await trainerCollection
        .doc(userId())
        .update({
          biography: { ...values, avatar: firebaseAuth.currentUser.photoURL }
        })

      message.success('Cadastro salvo!')
    } catch (error) {
      defaultCatch(error)
    }
    setSubmitting(false)
  }
})


export default Biography
