import styled from '@emotion/styled'
import { Button } from 'antd'

const arrColors = [
	{ color: 'green', hex: '#26C644' }
]

const color = props => arrColors.find(x => x.color === props.color).hex

const ButtonColor = styled(Button)`
	background-color: ${color};
	border-color: ${color};
	color: white;
	&:hover {
		color: ${color};
		border-color: ${color};
	}
`

export default ButtonColor
